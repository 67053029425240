import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { AuthFetch } from "../../../../../Redux/Reducers/AuthFetchReducer";

const WorkerFormStepTwo = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { initialValues, values } = props;
  const { isUser } = values;
  const [initRoles, setInitRoles] = useState([]);
  const { loggedWorker } = initialValues;

  useEffect(() => {
    const Fetch = (data) => dispatch(AuthFetch(data));
    const fetchData = async () => {
      const {
        payload: { data },
      } = await Fetch({ path: "/authorities/roleAll" });

      setInitRoles(data?.map((el) => ({ name: el.name, value: el.uuid })));
    };
    fetchData();
  }, [dispatch]);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:worker.accountDetails")} />
      <Row>
        <Col>
          <Field
            component={BMXTextField}
            fieldtype={"checkbox"}
            label={`${t("global:worker.isUser")}`}
            name="isUser"
            data-cy="isUser"
          />
        </Col>
      </Row>
      {isUser && (
        <Row>
          <Col xs={5}>
            <Field
              component={BMXTextField}
              label={`${t("global:worker.login")}*`}
              name="login"
              data-cy="login"
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={5}>
          <Field
            component={BMXSelectField}
            data-cy="roles"
            values={initRoles}
            selectedValues={values}
            label={`${t("global:worker.roles")}*`}
            name="roles"
            initialValues={{
              roles: initialValues?.initRoles?.map((e) => ({
                value: e.uuid,
                name: e.name,
              })),
            }}
            multiple
            displayEmpty
            renderValue={(el) => {
              return el.map((e) => e.name).join(", ");
            }}
          />
        </Col>
      </Row>
      {(loggedWorker?.admin || loggedWorker?.superAdmin) && isUser ? (
        <div className=" mt-3">
          <BMXSeparator title={t("global:worker.adminDetails")} />
          <Row>
            <Col xs={5}>
              <Field
                component={BMXSelectField}
                values={[
                  { name: t("global:yes"), value: true },
                  { name: t("global:no"), value: false },
                ]}
                label={`${t("global:worker.isAdmin")}*`}
                name="isAdmin"
                data-cy="isAdmin"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Field
                data-cy="password"
                type="password"
                component={BMXTextField}
                autocomplete="off"
                label={`${t("global:worker.password")}*`}
                name="password"
              />
            </Col>
            <Col xs={6}>
              <Field
                data-cy="passwordRepeat"
                type="password"
                component={BMXTextField}
                autocomplete="off"
                label={`${t("global:worker.passwordRepeat")}*`}
                name="passwordRepeat"
              />
            </Col>
          </Row>
          <Row>
            <span
              style={{
                overflowWrap: "break-word",
                fontSize: "0.8rem",
                display: "-webkit-box",
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
              }}
            >
              {t("global:worker.passwordInfoValid")}
            </span>
          </Row>
        </div>
      ) : null}
    </div>
  );
};

export default WorkerFormStepTwo;
